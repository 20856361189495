<template>
    <section>
        <div class='box' v-if="model != null && model.guia != null">
            <div class="level">
                <div class="level-item">
                    <router-link :to="{ name: 'portalconvenioguia', params: { id: model.guia.id }}" class="button is-primary">
                        {{ model.guia.id }}
                    </router-link>
                </div>
                <div class="level-item">
                    <small>{{$t('SISTEMA.PACIENTE')}}:</small>
                    <b>{{ model.guia.pacienteNome }}</b>
                </div>
                <div class="level-item">
                    <small>{{$t('WORKFLOW.DATARETORNO')}}:</small>
                    <b>{{ $moment(model.guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</b>
                </div>
            </div>
        </div>

        <div class="box" v-if="model != null && model.guia != null">
            <div class="columns">
                <div v-if="!model.geraEtiquetaPreColeta" class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">{{$t('WORKFLOW.ETIQUETA')}}</h3>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-default" :title="$t('WORKFLOW.IMPRIMIRETIQUETA')" @click="imprimirEtiquetaAmostraGuia()" :loading="loadingImpressaoEtiqueta" expanded>
                                {{$t('WORKFLOW.IMPRIMIRETIQUETA')}}
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">{{$t('WORKFLOW.COMPROVANTE')}}</h3>
                        </div>
                        <div class="panel-block">                            
                                <b-button type="is-default"  :title="$t('WORKFLOW.IMPRIMIRCOMPROVANTE')"
                                        expanded                                      
                                        @click="imprimeComprovanteGuia(model.guia.id, model.guia.convenioModeloComprovante)">
                                    {{$t('WORKFLOW.IMPRIMIRCOMPROVANTE')}}
                                </b-button>                                                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-multiline" v-if="model.geraEtiquetaPreColeta">
                <div class="column" >
                    <b-loading :active.sync="loadingImpressaoEtiqueta"></b-loading>
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">{{$t('WORKFLOW.COLETAEXTERNAPORTAL')}}</h3>
                        </div>
                        <div class="panel-block columns is-multiline">
                            <guiaAmostra v-for="(amostra, index) in amostras" :key="index" :amostra="amostra" :index="index" 
                                :mostra-paciente="false" :isPortal="true" :geraEtiquetaPreColeta="true"></guiaAmostra>
                            <nav class="column is-12 level">
                                <div class="level-item">
                                    <p class="buttons">
                                        <b-button type="is-success"
                                                native-type="button"
                                                :loading="salvandoDados"
                                                @click="vincularCodigoIntegracao();">
                                            {{$t('WORKFLOW.SALVAR')}}
                                        </b-button>
                                    </p>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-multiline" v-if="usuario.coletaExternaPortal">
                <div class="column" >
                    <b-loading :active.sync="loadingImpressaoEtiqueta"></b-loading>
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">{{$t('WORKFLOW.VINCULARETIQUETA')}}</h3>
                        </div>
                        <div class="panel-block columns is-multiline">
                            <guiaAmostra v-for="(amostra, index) in amostras" :key="index" :amostra="amostra" :index="index" 
                                :mostra-paciente="false" :isPortal="true" :coletaExternaPortal="true"></guiaAmostra>
                            <nav class="column is-12 level">
                                <div class="level-item">
                                    <p class="buttons">
                                        <b-button type="is-success"
                                                native-type="button"
                                                :loading="salvandoDados"
                                                @click="VincularColetaPortal();">
                                            {{$t('WORKFLOW.SALVAR')}}
                                        </b-button>
                                    </p>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex'
    import guiaAmostra from '@/components/atendimento/amostra.vue'
    import modalComprovanteVariavel from '@/components/atendimento/modalComprovanteVariavel.vue'

    export default {
        data() {
            return {
                model: null,
                amostras: [],
                id: this.$route.params.id,
                salvandoDados: false,
                loadingImpressaoEtiqueta: false,                
                modelosComprovante: [],                
               
            }
        },
        components: {
            guiaAmostra
        },
        computed: {
    
            ...mapState([
                'config',
                'usuario'
            ])
        },
        mounted() {
            this.salvandoDados = true;

            this.$http.get('/api/atendimento/ListarComprovanteModelos')
                .then(res => {
                    this.modelosComprovante = res.data;
                });
           
            this.$http.get(`/api/portal/Workflow?id=${this.$route.params.id}`)
                .then(({ data }) => {
                    this.model = data;

                    if(this.model.geraEtiquetaPreColeta || this.usuario.coletaExternaPortal){
                        this.buscarAmostras();
                    }
                })
                .catch((error) => {
                    this.model = null
                    throw error
                })
                .finally(() => {
                    this.salvandoDados = false
                })
        },
        methods: {
            vincularCodigoIntegracao(){
                this.salvandoDados = true;
                this.$http.post(`/api/portal/vincularAmostraPreColeta`, this.amostras )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: this.$t('WORKFLOW.MENSAGEMVINCULO'),
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    });  
            },
            async VincularColetaPortal(){

                if(this.usuario.coletaExternaPortal){

                    let campoValidos = true;
                        
                    this.amostras.forEach(a => {

                        if(!a.portalColetadorData ){
                            campoValidos =false;
                        }  

                    });

                    if(!campoValidos){    
                        this.$buefy.dialog.alert({
                                title: this.$t('SISTEMA.ATENCAO'),
                                message: this.$t('WORKFLOW.CAMPOSOBRIGATORIOS'),
                                type: 'is-warning',
                                hasIcon: true                                          
                            });                 
                        return true;            
                    
                    }                

                }
                
                this.salvandoDados = true;
                this.$http.post(`/api/portal/VincularColetaPortal`, this.amostras )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: this.$t('WORKFLOW.MENSAGEMVINCULO'),
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    });  
            },            
            buscarAmostras(){
                this.loadingImpressaoEtiqueta = true;

                let url = '/api/atendimento/GuiaAmostrasEdit?guiaId=' + this.$route.params.id;

                if(this.usuario.coletaExternaPortal){
                    url += "&infoColetadorPortal=true";
                }
                
                this.$http.get(url)
                    .then(resamos => {
                        this.amostras = resamos.data;

                        this.amostras.forEach(a => { 
                            if(a.portalColetadorData)
                                a.portalColetadorData =  this.$moment(a.portalColetadorData).toDate();                            
                        });
                        
                        this.loadingImpressaoEtiqueta = false;
                    })
            },
            imprimirEtiquetaAmostraGuia() {
                this.loadingImpressaoEtiqueta = true
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [this.model.guia.id],
                    null, true)
                    .then(() => {
                        this.loadingImpressaoEtiqueta = false
                    })
                    .catch(e => {
                        this.loadingImpressaoEtiqueta = false
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('INTEGRACAO.ERROIMPRIMIR') + e.toString(),
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
            },           
            imprimeComprovanteGuia(guia, modelo){               
                window.open('/Guia/Comprovante?id=' + guia + '&modelo=' + modelo);
                
            },
            preencherVariaveis(comprovante) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalComprovanteVariavel,
                    props: {
                        guia: this.guia,
                        comprovante: comprovante                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: false
                })
            },               
        }
    }
</script>